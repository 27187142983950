import { Component , OnInit} from '@angular/core';
import { locale, loadMessages } from "devextreme/localization";

import * as esMessages from "devextreme/localization/messages/es.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor() {

    loadMessages(esMessages);
    locale(navigator.language);

  }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
