export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  // url_api: 'https://api.agroag.net/api/',
  url: 'https://aguascalientes.1828brasaycarbon.com/',
  url_api: 'https://api.1828brasaycarbon.com/',
  // url_reportes: 'https://reportes.maindsoft.club',
  url_reportes: 'https://api.1828brasaycarbon.com/',
  assets_paths: {
    cavas: 'public/fotos/cava_',
    config: 'public/config_gen/'
  },
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
