<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © 1828 Brasa y Carbón
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Hecho con ♥ por Maindsoft
                </div>
            </div>
        </div>
    </div>
</footer>