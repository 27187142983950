import { Injectable } from '@angular/core';
import { MenuItem } from 'src/app/layouts/sidebar/menu.model';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor() {
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    /**
     * Returns the current user
     */
    public currentUser(): any {
        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(user: any) {
        localStorage.setItem('user-token', new Date().toISOString())
        localStorage.setItem('user', JSON.stringify(user));
        this.user = user;
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        localStorage.clear();
    }

    OrdenarMenu(array: any){
        let menu_primario: MenuItem[] = [
          {
            id: 0,
            label: 'MENUITEMS.MENU.TEXT',
            isTitle: true
          }
        ];
    
        array.forEach(element => {
          if (element.NIVEL == 0) {
            let sub_items: MenuItem[] = [];
            array.forEach(element2 => {
              let principal = element2.CAMINO.split('.');
              if(principal.length > 1 && principal[0] == element.CAMINO){
                let subitem: MenuItem = {
                  id: element2.ID_MODULO,
                  label: element2.NOMBRE_MODULO,
                  link: element2.RUTA,
                  parentId: parseInt(element.CAMINO)
                };
                sub_items.push(subitem);
              }
            });
    
            let item: MenuItem = {
              id: element.ID_MODULO,
              label: element.NOMBRE_MODULO,
              icon: element.ICONO,
              link: element.RUTA,
              subItems: sub_items
            };
            menu_primario.push(item);
          }
        });
        
        return menu_primario;
      }
}

