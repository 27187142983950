import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  @ViewChild('action') action: ElementRef;

  scanFlag: boolean = false;
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
  };
  error: any = null;

  inputToken: string = '';

  innerWidth: number;
	innerHeight: number;
	videoWidth: number;
	videoHeight: number;

  constructor(
    private router: Router, 
    private toast: ToastService,
    private api: ApiService
  ) { 
    (document.querySelector("body") as HTMLElement).style.overflow = "hidden";
    this.onResize(null)
  }

  ngOnInit(): void {
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    // e && action && action.pause();
    console.log('DEBUG QR::::::', {e});

    if(e.length > 0 && e[0].value!==undefined && e[0].value!==null && e[0].value.includes(environment.url)){
      let url = e[0].value.split('/');
      let token = url[url.length - 1];

      console.log('DEBUG TOKEN READ:::::', {token})

      this.api.verificarToken({TOKEN: token}).subscribe((res: any) => {
        this.error = null;
        
        this.action['stop']().subscribe((r: any) => {
          this.scanFlag = false;
        }, alert);

        this.router.navigateByUrl(`/cliente/cava/${token}`)
      }, err => {
        console.log('DEBUG ERR TOKEN:::::', {err})
        this.error = 'Escanea un código válido';
      })

    }else{
      this.error = 'Escanea un código válido';
    }

  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => {
        console.log(fn, r)
        this.scanFlag = true;
      }, alert);
    } else {
      action[fn]().subscribe((r: any) => {
        console.log(fn, r)
        this.scanFlag = false;
      }, alert);
    }
  }

  ingresarCava(){
    console.log('DEBUG TOKEN::::::', {token: this.inputToken})

    if(this.inputToken.trim().length > 0){
      if(this.inputToken.includes(environment.url)){
        let url = this.inputToken.split('/');
        let auxToken = url[url.length - 1];

        this.api.verificarToken({TOKEN: auxToken}).subscribe((res: any) => {

          if(this.scanFlag){
            this.action['stop']().subscribe((r: any) => {
              this.scanFlag = false;
            }, alert);
          }

          this.router.navigateByUrl(`${this.inputToken}`)
        }, err => {
          console.log('DEBUG ERR TOKEN:::::', {err})
          this.toast.Error('Token inválido')
        })

      }else{
        this.api.verificarToken({TOKEN: this.inputToken}).subscribe((res: any) => {

          if(this.scanFlag){
            this.action['stop']().subscribe((r: any) => {
              this.scanFlag = false;
            }, alert);
          }

          this.router.navigateByUrl(`/cliente/cava/${this.inputToken}`)
        }, err => {
          console.log('DEBUG ERR TOKEN:::::', {err})
          this.toast.Error('Token inválido')
        })
        
      }
    }else{
      this.toast.Error('Ingresa un token válido')
    }
  }

  // @HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.screen.availWidth;
		this.innerHeight = window.screen.availHeight;
		this.handleVideoResize()
		console.log('DEBUG SIZE::::::', {width: this.innerWidth, height: this.innerHeight})
	}

	handleVideoResize(){
		if(this.innerHeight < this.innerWidth){
			this.videoHeight = this.innerWidth/1.758;
			this.videoWidth = this.innerWidth;
		}else{
			this.videoHeight = this.innerHeight;
			this.videoWidth = this.innerHeight*1.758;
		}
	}

}
