import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

import { Page404Component } from './extrapages/page404/page404.component';
import { NoauthGuard } from './core/guards/noauth.guard';
import { ClienteComponent } from './layouts/cliente/cliente.component';
import { IndexComponent } from './cliente/index/index.component';
import { TokenGuard } from './core/guards/token.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [NoauthGuard] },
  { path: 'admin', component: LayoutComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: '', component: IndexComponent, loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule) },
  { path: 'cliente', component: ClienteComponent, loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule) },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
