import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toast: any;

  constructor() {
    this.toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  Success(title: string = '', message: string = ''){
    this.Toast('success', title, message);
  }

  Error(title: string = '', message: string = ''){
    this.Toast('error', title, message);
  }

  Info(title: string = '', message: string = ''){
    this.Toast('info', title, message);
  }

  Confirm(title: string = '', message: string = '', onConfirm = ()=>{}, onCancel = ()=>{}){
    Swal.fire({
      title: title,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Entendido",
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm()
      }else{
        onCancel()
      }
    });
  }

  Message(title: string = '', message: string = '', onConfirm = ()=>{}, confirmText = '', showCancel = false){
    Swal.fire({
      title: title,
      html: message,
      icon: "info",
      showCancelButton: showCancel,
      confirmButtonColor: "#3085d6",
      confirmButtonText: confirmText,
      cancelButtonColor: "#111",
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if(result.isConfirmed){
        onConfirm()
      }
    });
  }

  private Toast(type: string = 'success', title: string = '', message: string = ''){
    this.toast.fire({
      icon: type,
      title: title,
      text: message
    })
  }
}
