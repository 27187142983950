<div class="general-bg overflow-hidden">
    <iframe src="https://player.vimeo.com/video/932182541?muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;app_id=122963" class="bgVideo position-fixed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="1828 Brasa y Carbón" data-ready="true" [style]="{height: videoHeight+'px', width: videoWidth+'px'}"></iframe>
    <div class="container-fluid d-flex justify-content-center flex-column w-100 index-content" >
        <div class="row gap-3 justify-content-evenly align-items-center" style="overflow-y: auto;">
            <div class="col-lg-4 col-6 text-center">
                <img src="assets/images/logo-web-1828.webp" class="img-fluid" alt="">
            </div>
            <div class="col-lg-4">
                <div class="card login-card" style="border-radius: 1rem;">
                    <div class="card-body">
                        <h1 class="text-white fw-bold fs-3 text-center px-3">Escanea el código QR de tu cava</h1>
                        <ngx-scanner-qrcode class="{{ scanFlag ? 'camera-container' : 'd-none'}}" #action="scanner" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
                        <div class="d-flex w-100 justify-content-center py-3" *ngIf="!scanFlag">
                            <button class="btn btn-primary fw-bold" (click)="handle(action,'start')">
                                <i class="bx bxs-camera me-1"></i>
                                Escanear
                            </button>
                        </div>
                        <p class="text-danger fw-bold text-center" *ngIf="error!==null">
                            <i class="fa fa-exclamation-triangle me-1"></i>
                            {{ error }}
                        </p>
    
                        <div class="d-flex align-items-center gap-3">
                            <div class="flex-fill">
                                <hr />
                            </div>
                            <span><strong>O</strong></span>
                            <div class="flex-fill">
                                <hr />
                            </div>
                        </div>
    
                        <div class="mb-3">
                            <label for="search" class="form-label">Ingresa tu token</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Token" [(ngModel)]="inputToken">
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-center">
                            <button class="btn btn-primary fw-bold" [disabled]="inputToken.trim().length === 0" (click)="ingresarCava()">
                                Ingresar
                            </button>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>