import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as internal from 'stream';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  public getMenu(user: any) {
    return this.http.get(environment.url_api + "usuarios/GetMenuAcceso/" + encodeURI(user));
  }

  public obtenerNivelesAccesoModulo(params: { ID_MODULO: number, ID_USUARIO: number }) {
    return this.http.get(`${environment.url_api}Perfiles/GetNivelAccesoModulo?id_modulo=${encodeURI(params.ID_MODULO.toString())}&id_usuario=${encodeURI(params.ID_USUARIO.toString())}`);
  }

  public getAcceso(params: {USUARIO: string, CONTRASENIA: string}) {
    return this.http.post(`${environment.url_api}usuarios/GetAcceso`, params);
  }

  /* CLIENTES */
  public getClientes(params: {ID_USUARIO: number}){
     return this.http.get(`${environment.url_api}usuarios/GetClientes?id_usuario=${encodeURI(params.ID_USUARIO.toString())}`);
  }
  /* CLIENTES */

  /* USUARIOS */
  public getUsuario(params: {ID_CLIENTE: number, ID_USUARIO: number}){
     return this.http.get(`${environment.url_api}usuarios/obtenerUsuario/${encodeURI(params.ID_CLIENTE.toString())}/${encodeURI(params.ID_USUARIO.toString())}`);
  }
  public putUsuario(params: {ID_USUARIO: number, ID_USUARIO_USO: number, NOMBRE: string, APELLIDO_PATERNO: string, APELLIDO_MATERNO: string, TELEFONO: string}){
    return this.http.put(`${environment.url_api}usuarios/editarUsuario`, params);
  }
  public getDatosUsuario(params: {ID_USUARIO: number}){
    return this.http.get(`${environment.url_api}usuarios/obtenerDatos/${encodeURI(params.ID_USUARIO.toString())}`);
  }
  public postPassword(params: {ID_USUARIO: number, PASSWORD_ACTUAL: string, PASSWORD_NUEVO: string, PASSWORD_NUEVO_CONFIRM: string}){
    return this.http.post(`${environment.url_api}usuarios/actualizarContrasenia`, params);
  }
  /* USUARIOS */

  /* CAVAS */
  public getCavas(params: {ID_USUARIO: number, ID_USUARIO_USO: number} = {ID_USUARIO_USO: 0, ID_USUARIO: 0}){
    return this.http.get(`${environment.url_api}cavas/getCavas/${encodeURI(params.ID_USUARIO.toString())}`);
  }
  public getCavasList(params: {ID_USUARIO: number}){
    return this.http.get(`${environment.url_api}cavas/getCavasList/${encodeURI(params.ID_USUARIO.toString())}`);
  }
  public getCavasCliente(params: {ID_USUARIO: number, ID_CLIENTE}){
    return this.http.get(`${environment.url_api}cavas/obtenerCavasCliente?ID_USUARIO=${encodeURI(params.ID_USUARIO.toString())}&ID_CLIENTE=${encodeURI(params.ID_CLIENTE.toString())}`);
  }
  public getInfoCava(params: {ID_USUARIO: number, TOKEN}){
    return this.http.get(`${environment.url_api}cavas/obtenerCavaToken?ID_USUARIO=${encodeURI(params.ID_USUARIO.toString())}&TOKEN=${encodeURI(params.TOKEN.toString())}`);
  }
  public postCava(params: {ID_USUARIO: number, ID_CLIENTE: number, NOMBRE_CLIENTE: string, APELLIDO_PATERNO_CLIENTE: string, APELLIDO_MATERNO_CLIENTE: string, TELEFONO: string}){
    return this.http.post(`${environment.url_api}Usuarios/GetAcceso`, params);
  }

  public postCavaUsuario(params: {ID_USUARIO: number, ID_CLIENTE: number, NOMBRE: string, APELLIDO_PATERNO_CLIENTE: string, APELLIDO_MATERNO_CLIENTE: string, TELEFONO: string}){
    return this.http.post(`${environment.url_api}cavas/registrarUsuarioCava`, params);
  }

  public deleteCava(params: {ID_CAVA: number, ID_USUARIO: number}){
    return this.http.delete(`${environment.url_api}cavas/eliminarCava?ID_CAVA=${encodeURI(params.ID_CAVA.toString())}&ID_USUARIO_USO=${encodeURI(params.ID_USUARIO.toString())}`);
  }

  public verificarToken(params: {TOKEN: string}){
    return this.http.get(`${environment.url_api}cavas/verificarToken/${encodeURI(params.TOKEN.toString())}`);
  }

  public verificarCavaExiste(params: {NUMERO_CAVA: number, ID_USUARIO_USO: number}){
    return this.http.post(`${environment.url_api}cavas/verificarCava`, params);
  }
  /* CAVAS */

  /* PERSONAS EXTRA */
  public agregarPersonaAdicionalCava(params: any[]){
    return this.http.post(`${environment.url_api}personas_extra/agregarPersonaAdicionalCava`, params);
  }

  public obtenerPersonasExtraCava(params: {ID_CAVA: number, ID_USUARIO_USO: number}){
    return this.http.get(`${environment.url_api}personas_extra/obtener_personas_extra_cava/${encodeURI(params.ID_CAVA.toString())}/${encodeURI(params.ID_USUARIO_USO.toString())}`);
  }

  public deletePersonaExtraCava(params: any[]){
    return this.http.post(`${environment.url_api}personas_extra/eliminarPersonaAdicionalCava`, params);
  }
  /* PERSONAS EXTRA */
  
  /* CONSUMO CAVAS */
  public getConsumos(params: {ID_USUARIO: number}){
    return this.http.get(`${environment.url_api}consumo_cavas/getConsumos?id_usuario=${encodeURI(params.ID_USUARIO.toString())}`);
  }
  public getConsumosCavas(params: {ID_USUARIO: number, ID_CAVA: number}){
    return this.http.get(`${environment.url_api}consumo_cavas/getConsumosCava?ID_USUARIO=${encodeURI(params.ID_USUARIO.toString())}&ID_CAVA=${encodeURI(params.ID_CAVA.toString())}`);
  }
  public postConsumoCavas(params: any){
    return this.http.post(`${environment.url_api}consumo_cavas/insertarConsumo_Cava`, params);
  }
  public updateConsumoCavas(params: any){
    return this.http.post(`${environment.url_api}consumo_cavas/finConsumo_Cava`, params);
  }
  public deleteConsumoCavas(params: {ID_USUARIO: number, ID_CONSUMO_CAVA: number}){
    return this.http.delete(`${environment.url_api}consumo_cavas/eliminarConsumo_Cava?ID_USUARIO=${encodeURI(params.ID_USUARIO.toString())}&ID_CONSUMO_CAVA=${encodeURI(params.ID_CONSUMO_CAVA.toString())}`);
  }
  
  /* CONSUMO CAVAS */

  /* CONFIGURACIÓN */
  public getConfig(params: {ID_USUARIO_USO: number}){
    return this.http.get(`${environment.url_api}config/getConfig?ID_USUARIO_USO=${encodeURI(params.ID_USUARIO_USO.toString())}`);
  }
  /* CONFIGURACIÓN */

  /* CONFIGURACIÓN */
  public obtenerConfig(ID_USUARIO){
    return this.http.get(`${environment.url_api}config/obtener_config/${encodeURI(ID_USUARIO.toString())}`);
  }

  public editarConfig(params: any){
    return this.http.post(`${environment.url_api}config/editar_config`, params);
  }

  /* CONFIGURACIÓN */

}