import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading: boolean = false;

  public isLoading = new BehaviorSubject(false);
    
  constructor() { }

  setLoading(loading: boolean){
    this.loading = loading
  }

  getLoading(): boolean{
    return this.loading
  }
}
