<div class="main-content m-0">

    <header id="page-topbar" class="bg-dark">
        <div class="navbar-header">
            <div class="d-flex w-100 justify-content-center">
                <div class="navbar-brand-box bg-transparent d-block border-0">
                    <a routerLink="/" class="logo logo-light">
                      <span class="logo-sm">
                        <img src="assets/images/logo-light.png" alt="" height="45">
                      </span>
                      <span class="logo-lg">
                        <img src="assets/images/logo-light.png" alt="" height="45">
                      </span>
                    </a>
                  </div>
            </div>
        </div>
    </header>

    <div id="cont-router" class="container-fluid py-3" style="margin-top: 0px; height: calc(100vh - 0px); max-height: calc(100vh - 0px)">
        <router-outlet></router-outlet>
    </div>

    <footer class="footer m-0 start-0">
        <div class="container-fluid d-flex justify-content-center">
            <p class="mb-0 text-center">
                {{year}} © 1828 Brasa y Carbón
            </p>
        </div>
    </footer>
</div>
